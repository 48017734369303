@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

.servicepage-back{
  background-color: #121d47;
  font-family: 'DM Sans', sans-serif;
  width: 100%;
  padding-bottom: 112px;
  .servicepage{
    display: flex;
    flex-direction: column;
    .title{
      margin-left: auto;
      margin-right: auto;
      color: #F7FFF7;
      font-size: 71px;
      font-style: normal;
      font-weight: 500;
      line-height: 120.5%; /* 85.555px */
    }
    .text{
      margin-left: auto;
      margin-right: auto;
      margin-top: 4px;
      max-width: 675px;
      color: #F7FFF7;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 126.5%; /* 22.77px */
    }
    .logo{
      margin-left: auto;
      margin-right: auto;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-top: 27px;
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      img{
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
      .name{
        text-align: left;
        font-size: 43px;
        font-style: normal;
        font-weight: 700;
        line-height: 145.4%; /* 62.522px */
        margin-top: -5px;
        margin-bottom: auto;
      }
    }
    .node-box{
      margin-left: auto;
      margin-right: auto;
      min-width: 615px;
      max-width: 900px;
      width: calc(100% - 500px);
      border-radius: 11px;
      background: #152461;
      height: 147px;
      flex-shrink: 0;
      .upper{
        margin: 18px 29px 0px 20px;
        display: flex;
        flex-direction: row;
        .left{
          color: #FFF;
          font-size: 23px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding-right: 14px;
          border-right: 2px solid #D9D9D9;
          margin-top: auto;
          margin-bottom: auto;
        }
        .right{
          margin-left: 14px;
          color: #FFF;
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: auto;
          margin-bottom: auto;
        }
        .icon{
          width:20px;
          height:20px;
          flex-shrink: 0;
          margin-left: auto;
          margin-top: auto;
          margin-bottom: auto;
          cursor: pointer;
        }
      }
      .below{
        padding-top: 11px;
        border-top: 1px solid #D9D9D9;
        flex-shrink: 1;
        margin-top: 21px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        color: #DCDCDC;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media (max-width:768px) {
          font-size: 16px;
        }
        @media (max-width:600px) {
          font-size: 12px;
        }
        @media (max-width:480px) {
          font-size: 9px;
        }
      }
      .snap {
        line-height: 35px;
        font-size: 22px;
        text-align: center;
      }
      .belowtext{
        margin-top: 14px;
        margin-bottom: 20px;
        flex-shrink: 1;
        margin-top: 21px;
        margin-left: 20px;
        color: #DCDCDC;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media (max-width:768px) {
          font-size: 16px;
        }
        @media (max-width:600px) {
          font-size: 12px;
        }
        @media (max-width:480px) {
          font-size: 9px;
        }
      }
      .below a, .belowtext a {
        font-size: 22px;
        font-weight: 800;
        color: #fafafa;
      }
    }
  }
}

.buttons-restake {
  height: 30px;
  width: 200px;
  background: #121d47;
  border: 1px solid #465aa4;
  border-radius: 10px;
  padding: 10px 5px 10px 10px;
  margin-top: 10px;
}

.ibc {
  display: inline-block;
  height: 120px;
  width: 380px;
  background: #121d47;
  border: 1px solid #465aa4;
  border-radius: 10px;
  padding: 10px 5px 10px 10px;
  margin: 10px;

}

.ibc span {
  display: inline-block;
  text-align: center;
  margin: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border: 0px solid #fff;
  width: 115px;
}

.ibc img {
  width: 70px;
  height: 70px;
  border-radius: 50px;
}


.toolBoxMain {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
}

.toolBox {
  width: 200px;
  border: 5px solid #334388;
  text-align: center;
  border-radius: 10px;
  padding: 25px 15px 25px 15px;
  color: #fff;
  font-size: 25px;
  background: #1c2c6f;
  margin: 10px;
  display: inline-block;
  font-weight: 500;
}

.toolBox:hover {
  background: #d1d4df;
  color: #111;
  cursor: pointer;
}



.toolBoxBack {
  width: 150px;
  border: 2px solid #334388;
  text-align: center;
  border-radius: 10px;
  padding: 5px 5px 5px 5px;
  color: #fff;
  font-size: 20px;
  background: #1c2c6f;
  margin: 10px;
  display: inline-block;
  font-weight: 500;
}

.toolBoxBack:hover {
  background: #d1d4df;
  color: #111;
  cursor: pointer;
}

.myurl {
  display: block;
  font-size: 22px;
  padding: 0px;
  margin: -25px 0 0 5px;
  text-align: left;
  font-weight: normal;
}

.myurl a {
  color: cornflowerblue;
}

.myhr {
  width: 99%;
  height: 0px;
  margin-top: 20px;
  background: rgb(29, 47, 100);
  border-color: rgb(29, 47, 100);
}

.myStake {
  margin: 0 auto 0 auto;
  padding: 15px 30px 15px 30px;
  background: cornflowerblue;
  border-radius: 20px;
}

.myStake a {
  text-decoration: none;
  color: #121D47;
  font-size: 20px;
  font-weight: bold;
}

.toolBoxMain p {
  color: #fff;
  font-size: 20px;
  font-weight: 900;
}