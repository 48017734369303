@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

.measure-back{
  background-color: #0B1437;
  font-family: 'DM Sans', sans-serif;
  padding-top: 103px;
  padding-bottom: 103px;
  padding-left: 50px;
  padding-right: 50px;
  .measure{
    max-width: 1208px;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap:5%;
    margin-left:auto;
    margin-right:auto;
    .subtitle{
      color: #4157A4;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 111%; /* 24.42px */
      letter-spacing: -0.44px;
      @media (max-width:768px){
        text-align: center;
      }
    }
    .title{
      color: #F7FFF7;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 111%; /* 44.4px */
      letter-spacing: -0.8px;
      @media (max-width:768px){
        text-align: center;
      }
    }
    .text{
      margin-top: 9px;
      max-width: 502px;
      color: #CFCDCD;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 111%; /* 18.87px */
      letter-spacing: -0.34px;
      @media (max-width:768px){
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }
    .contents{
      text-align: left;
      display: grid;
      grid-template-columns: 45% 45%;
      margin-top: 27px;
      column-gap: 10%;
      @media(max-width:768px){
        grid-template-columns: auto;
        gap: 0px;
        padding: 0;
      }
      .item{
        display: flex;
        flex-direction: row;
        margin-top: 27px;
        .icon-panel{
          width:78px;
          height:78px;
          flex-shrink: 0;
          border-radius: 50%;
          background-color: #121D47;
          padding:auto;
          display: flex;
          text-align: center;
          margin-right: 12px;
          .icon{
            flex-shrink: 0;
            margin:auto;
            width:52px;
            height:52px;
          }
          .icon1{
            flex-shrink: 0;
            margin:auto;
            width:47px;
            height:47px;
          }
        }
        .content-panel{
          margin-top: 10px;
          .content-title{
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 22px */
            letter-spacing: -0.44px;
          }
          .content-text{
            margin-top: 9px;
            color: #CFCDCD;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 111%; /* 18.87px */
            letter-spacing: -0.34px;
          }
        }
      }
    }
  }
}