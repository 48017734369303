@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

@keyframes WalletAnime {
  0% {
    opacity: 0;
    transform: translateY(+80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.wallet{
  background-color: #0B1437;
  font-family: 'DM Sans', sans-serif;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  border-bottom: 5px solid #1f2b5a;
  .subtitle{
    color: #4157A4;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 111%; /* 24.42px */
    letter-spacing: -0.44px;
    animation: WalletAnime 2s ease-in-out;
  }
  .title{
    color: #F7FFF7;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 40px */
    letter-spacing: -0.8px;
    animation: WalletAnime 2s ease-in-out;
  }
  .content{
    text-align: center;
    margin-top: 5px;
    color: #CFCDCD;
    font-family: DM Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 17px */
    letter-spacing: -0.34px;
    animation: WalletAnime 2s ease-in-out;
  }
  .list{
    margin-top: 27px;
    max-width: 868px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    row-gap: 30px;
    justify-content: center;
    flex-wrap: wrap;
    animation: WalletAnime 2s ease-in-out;
    .item{
      display: block;
      border-radius: 11px;
      background: #121D47;
      width:136px;
      height:126px;
      text-align: center;
      transition: background-color 0.3s;
      cursor: pointer;
      flex-shrink: 0;
      &:hover{
        background-color: #4157A4;
      }
      .image{
        margin-top: 19px;
        width: 60px;
        height: 60px;
      }
      .text{
        margin-top: 17px;
        color: #CFCDCD;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 17px */
        letter-spacing: -0.34px;
      }
    }
  }
}

.whatsNew {
  color: #c8c6c6;
  text-align: center;
  border: 3px solid #0d163e;
  margin: auto;
  max-width: 700px;
  width: 100%;
  background-color: #1b2969;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
}

.whatsNew a {
  color: #c8c6c6;
}

.whatsNew img {
  width: 32px;
  height: 32px;
  margin-bottom: -7px;
  margin-left: 5px;
  border: 1px solid #686161;
  border-radius: 50%;
}