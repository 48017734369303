@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;500;700&display=swap');

.Header{
  background-color: #121D47;
  padding:28px 50px 28px 50px;
  display: flex;
  flex-direction: row;
  border-bottom: 5px solid #152461;
  .logo-panel{
    display: flex;
    flex-direction: row;
    text-decoration: none;
    flex-shrink: 0;
    .logo{
      width: 42px;
      height: 42px;
      margin-right: 8px;
      margin-left: -15px;
    }
    .head{
      color: white;
      font-weight: bold;
      font-size: 30px;
      font-family: 'DM Sans', sans-serif;
      text-decoration: none;
    }
  }
  .links{
    display: flex;
    flex-direction: row;
    gap:50px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    @media (max-width:1000px) {
      gap:30px;
    }
    @media (max-width:768px){
      display: none;
    }
    .link{
      font-size: 17px;
      font-family: 'DM Sans', sans-serif;
      font-weight: medium;
      text-decoration: none;
      color:white;
      &:hover{
        color: blue;
      }
    }
  }
  
  
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  right: 15px;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  top: 15px;
  z-index: 1;

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: white;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  @media (max-width: 768px) {
    display: flex;
    margin-left: 15px;
  }
}

.popupMenu {
  background: #fff;
  border-radius: 8px;
  position: absolute;
  top: 63px;
  right: 50px;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;

  .link {
    color: #000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 17px;
    font-family: 'DM Sans', sans-serif;
    font-weight: medium;

    &:hover {
      background-color: #f1f1f1;
    }
  }
}
}