@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

.back{
  background-color: #0B1437;
  padding-left: 50px;
  padding-right: 50px;
  .intro{
    max-width: 1248px;
    display: flex;
    margin-left: auto;
    margin-right:auto;
    padding-top:30px;
    padding-bottom:60px;
    flex-direction: row;
    font-family: 'DM Sans', sans-serif;
    gap:10%;
    animation: fadeIn 2s ease-in-out;
    @media (max-width:768px) {
      text-align: center;
      grid-template-columns: auto;
      padding-top:30px;
      padding-bottom:60px;
    }
    li {
      font-size: 30px;
      color: #F7FFF7;
    }
    .text{
      margin-top: auto;
      margin-bottom: auto;
      animation: fadeIn 2s ease-in-out;
      .title{
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.5%; /* 54.225px */
        color: #F7FFF7;
        margin-bottom: 6px;
        text-align: left;
        @media (max-width:1000px) {
          text-align: center;
          font-size: 35px;
        }
        .subtitle{
          color: #F7FFF7;
          background-color: #222F62;
          font-size: 55px;
          font-style: normal;
          font-weight: 700;
          line-height: 135.5%;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 10px;
        }
      }
      .content{
        margin-top: 6px;
        color: #F7FFF7;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 126.5%; /* 20.24px */
        text-align: left;
        @media (max-width:1000px) {
          text-align: center;
          font-size: 18px;
        }
      }
      .button{
        display: flex;
        margin-top: 12px;
        border-radius: 65px;
        background-color: #4157A4;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width:186px;
        height:51px;
        text-align: center;
        transition: background-color 0.3s;
        cursor: pointer;
        &:hover{
          background-color: #121D47;
        }
        @media (max-width:1000px) {
          margin-left: auto;
          margin-right: auto;
        }
        .text{
          margin:auto;
          color: #F7FFF7;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 16px */
          letter-spacing: -0.32px;
        }
      }
    }
    .image{
      display: block;
      width:526px;
      height:423px;
      margin-left: auto;
      margin-top: auto;
      margin-bottom: auto;
      @media (max-width:1200px){
        img{
          width:420px;
          height:338px;
        }
      }
      @media (max-width:1000px){
        display: none;
      }
    }
  }
}