@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

.stats-back{
  background: #121D47;
  padding-top:38px;
  padding-bottom:38px;
  padding-left: 50px;
  padding-right: 50px;
  font-family: 'DM Sans', sans-serif;
  .stats{
    margin-left: auto;
    margin-right: auto;
    max-width: 1222px;
    .title{
      color: #F7FFF7;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 40px */
      letter-spacing: -0.8px;
    }
    .whiteLink {
      color: #fff !important;
    }
    .list{
      margin-top: 38px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      column-gap: 40px;
      row-gap: 20px;
      .content{
        flex-shrink: 0;
        width:150px;
        text-align: center;
        img {
          width: 96px;
          height: 94px;
          flex-shrink: 0;
          border-radius: 50%;
        }
        .subtitle{
          text-align: center;
          color: #4157A4;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 40px */
          letter-spacing: -0.8px; 
        }
        .title{
          color: #FFF;
          margin-top: 7px;
          margin-bottom: 7px;
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 25px */
          letter-spacing: -0.5px;
        }
        .text{
          color: #CFCDCD;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 17px */
          letter-spacing: -0.34px;
        }
      }
    }
  }
}