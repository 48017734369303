@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

.story-back{
  background-color: #0B1437;
  font-family: 'DM Sans', sans-serif;
  padding-top: 99px;
  padding-bottom: 99px;
  padding-left: 50px;
  padding-right: 50px;
  .story{
    max-width: 1241px;
    display: flex;
    flex-direction: row;
    gap:5%;
    margin-left:auto;
    margin-right:auto;
    .contents{
      text-align: left;
      .subtitle{
        color: #4157A4;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 111%; /* 24.42px */
        letter-spacing: -0.44px;
        @media (max-width:1000px) {
          text-align: center;
        }
      }
      .title{
        color: #F7FFF7;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 111%; /* 44.4px */
        letter-spacing: -0.8px;
        @media (max-width:1000px) {
          text-align: center;
        }
      }
      .text{
        margin-top: 8px;
        color: #CFCDCD;
        font-family: DM Sans;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 111%; /* 18.87px */
        letter-spacing: -0.34px;
        @media (max-width:1000px) {
          text-align: center;
        }
      }
      .item{
        display: flex;
        flex-direction: row;
        margin-top: 27px;
        .icon-panel{
          width:78px;
          height:78px;
          flex-shrink: 0;
          border-radius: 50%;
          background-color: #121D47;
          padding:auto;
          display: flex;
          text-align: center;
          margin-right: 12px;
          .icon{
            flex-shrink: 0;
            margin:auto;
            width:52px;
            height:52px;
          }
          .icon1{
            flex-shrink: 0;
            margin:auto;
            width:47px;
            height:47px;
          }
        }
        .content-panel{
          margin-top: 10px;
          .title{
            text-align: left;
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 22px */
            letter-spacing: -0.44px;
          }
          .text{
            text-align: left;
            color: #CFCDCD;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 111%; /* 18.87px */
            letter-spacing: -0.34px;
          }
        }
      }
      .button{
        margin-top: 32px;
        width: 215.176px;
        height: 59px;
        flex-shrink: 0;
        border-radius: 65px;
        border: 1px solid #4157A4;
        display: flex;
        transition: background-color 0.3s;
        cursor: pointer;
        @media (max-width:1000px) {
          margin-left: auto;
          margin-right: auto;
        }
        .text{
          margin:auto;
          color: #F7FFF7;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 18px */
          letter-spacing: -0.36px;
        }
        &:hover{
          background-color: #4157A4;
        }
      }
    }
    .image{
      margin-top: auto;
      margin-bottom: auto;
      width: 558px;
      height: 558px;
      @media (max-width:1200px){
          width:446px;
          height:446px;
      }
      @media (max-width:1000px){
        display: none;
      }
    }
  }
}