@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

.securityintro-back{
  background-color: #0B1437;
  display: flex;
  justify-content: center;
  font-family: 'DM Sans', sans-serif;
  padding-top: 129px;
  padding-bottom:103px;
  padding-left: 50px;
  padding-right: 50px;
  .securityintro{
    text-align: left;
    max-width: 1237px;
    display: grid;
    grid-template-columns: 50% 50%;
    @media (max-width:768px) {
      text-align: center;
      grid-template-columns: auto;
    }
    .left{
      margin-right: 45px;
      @media (max-width:768px){
        margin-right: 0;
        margin-bottom: 33px;
      }
      .title{
        color: #F7FFF7;
        font-size: 71px;
        font-style: normal;
        font-weight: 500;
        line-height: 120.5%; /* 85.555px */
        .subtitle{
          color: #F7FFF7;
          background-color: #4157A4;
          font-size: 71px;
          font-style: normal;
          font-weight: 700;
          line-height: 120.5%;
        }
      }
      .text{
        margin-top: 33px;
        color: #F7FFF7;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 126.5%; /* 22.77px */
      }
    }
    .right{
      color: #F7FFF7;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 126.5%; /* 22.77px */
    }
  }
}