@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

.contactform-back{
  background-color: #0B1437;
  font-family: 'DM Sans', sans-serif;
  width: 100%;
  padding-top: 71px;
  padding-bottom: 71px;
  .contactform{
    .title{
      color: #6941C6;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
    .subtitle{
      color: #FFF;
      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
    }
    .text{
      color: #FFF;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 166.667% */
    }
    .content{
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-left: 50px;
      padding-right: 50px;
      .form{
        margin-top:34px;
        max-width: 600px;
        .two-item{
          display: flex;
          text-align: left;
          flex-direction: row;
          width: 100%;
          column-gap: 32px;
          margin-bottom: 24px;
          .item{
            width: calc((100% - 32px)/2);
            .txt{
              color: #F4F4F4;
              /* Text sm/Medium */
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */
              margin-bottom: 8px;
            }
            .input{
              display: flex;
              padding: 12px 16px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid #FFF; 
              background: #4157A4;
              /* Shadow/xs */
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              .place{
                width: 100%;
                color: #FFF;
                background: #4157A4;
                outline: none;
                border: none;
                /* Text md/Normal */
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
                &::placeholder{
                  color:#FFF;
                  font-weight: 400;
                }
                &:focus{
                  outline: none;
                  border: none;
                }
              }
            }
          }
        }
      }
      .email{
        width: 100%;
        text-align: left;
        margin-bottom: 24px;
        .txt{
          color: #F4F4F4;
          /* Text sm/Medium */
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          margin-bottom: 8px;
        }
        .input{
          display: flex;
          padding: 12px 16px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid #FFF; 
          background: #4157A4;
          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          .place{
            width: 100%;
            color: #FFF;
            background: #4157A4;
            outline: none;
            border: none;
            /* Text md/Normal */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            &::placeholder{
              color:#FFF;
              font-weight: 400;
            }
            &:focus{
              outline: none;
              border: none;
            }
          }
        }
      }
      .phone{
        width: 100%;
        text-align: left;
        margin-bottom: 24px;
        .txt{
          color: #F4F4F4;
          /* Text sm/Medium */
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          margin-bottom: 8px;
        }
        .input{
          display: flex;
          padding: 12px 16px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid #FFF; 
          background: #4157A4;
          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          .country{
            flex-shrink: 0;
            color: var(--gray-900, #101828);
            /* Text md/Normal */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            display: flex;
            flex-direction: row;
            margin-right: 12px;
            .selector{
              height:100%;
              flex-shrink: 0;
              display: flex;
              margin-top:auto;
              margin-bottom: auto;
              color:white;
            }
          }
          .place{
            width: 100%;
            color: #FFF;
            background: #4157A4;
            outline: none;
            border: none;
            /* Text md/Normal */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            &::placeholder{
              color:#FFF;
              font-weight: 400;
            }
            &:focus{
              outline: none;
              border: none;
            }
          }
        }
      }
      .message{
        width: 100%;
        text-align: left;
        margin-bottom: 24px;
        .txt{
          color: #F4F4F4;
          /* Text sm/Medium */
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          margin-bottom: 8px;
        }
        .input{
          display: flex;
          height:128px;
          padding: 12px 16px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid #FFF; 
          background: #4157A4;
          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          .place{
            width: 100%;
            font-family: 'DM Sans', sans-serif;
            height:100%;
            color: #FFF;
            background: #4157A4;
            outline: none;
            border: none;
            /* Text md/Normal */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            &::placeholder{
              color:#FFF;
              font-weight: 400;
            }
            &:focus{
              outline: none;
              border: none;
            }
          }
        }
      }
      .check{
        width: 100%;
        text-align: left;
        margin-bottom: 32px;
        .box{
          width: 20px;
          height: 20px;
          border-radius: 6px;
          border: 1px solid #FFF;
          background: #4157A4;
          margin-right: 12px;
        }
        .txt{
          color: #FAFAFA;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          .sublink{
            color: #FAFAFA;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-decoration-line: underline;
          }
          .dot{
            color: #4157A4;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
      .button{
        display: flex;
        width:100%;
        height:48px;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #4157A4;
        background: #4157A4;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        .txt{
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }
    }
  }
}