@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

.subscribe-back{
  background-color: #0B1437;
  font-family: 'DM Sans', sans-serif;
  display: flex;
  justify-content: center;
  .subscribe{
    max-width: 682px;
    text-align: center;
    padding-top: 103px;
    padding-bottom: 99px;
    .title{
      color: #FFF;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 124.1%; /* 49.64px */
      letter-spacing: -0.8px;
      .subtitle{
        color: #7551FF;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 124.1%;
        letter-spacing: -0.8px;
      }
    }
    .text{
      color: #FFF;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .email{
      margin-top: 29px;
      border-radius: 12px;
      background: #18375C;
      height:50px;
      display: flex;
      flex-direction: row;
      padding:13px 24px 13px 29px;
      .input{
        margin-top: auto;
        margin-bottom: auto;
        text-align: left;
        width: 100%;
        background: #18375C;
        font-family: 'DM Sans', sans-serif !important;
        color: #A7A9A7;
        border: none;
        text-align: left;
        font-family: Manrope;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 124.1%; /* 21.097px */
        height:22px;
        &:focus{
          outline: none;
          box-shadow: none;
        }
      }
      .button{
        margin-left: auto;
        width:150px;
        flex-shrink: 0;
        height:50px;
        display: flex;
        justify-content: center;
        background-color: #7551FF;
        border-radius: 7px;
        .button-text{
          margin-top: auto;
          margin-bottom: auto;
          color: #FFF;
          font-size: 17px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}