@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

.testnet-back{
  background-color: #121D47;
  font-family: 'DM Sans', sans-serif;
  padding-top: 70px;
  padding-bottom: 54px;
  width:100%;
  .testnet{
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    .subtitle{
      color: #4157A4;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 111%; /* 24.42px */
      letter-spacing: -0.44px;
    }
    .title{
      color: #FFF;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 145.4%; /* 58.16px */
      letter-spacing: -0.8px;
    }
    .text{
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
      color: #FFF;
      text-align: center;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 145.4%; /* 24.718px */
    }
    .widget{
      margin-top: 72px;
      margin-left:auto;
      margin-right:auto;
      display: flex;
      text-align: center;
      flex-wrap: wrap;
      justify-content: center;
      column-gap:25px;
      row-gap: 72px;
      .item{
        display: block;
        position: relative;
        width: 298px;
        height: 288px;
        flex-shrink: 0;
        border-radius: 11px;
        background: #152461;
        .icon{
          position: absolute;
          top: -50%;
          left: 50%;
          transform: translate(-50%, +100%);
          width: 96px;
          height: 94px;
          flex-shrink: 0;
          border-radius: 50%;
          background-color: #111D4E;
          img{
            width:100%;
            height:100%;
            border-radius: 50%;
          }
        }
        .title{
          margin-top: 72px;
          color: #FFF;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 24px */
          letter-spacing: -0.48px;
        }
        .text{
          margin-left: 25px;
          margin-right: 25px;
          max-width: 500px;
          margin-top:8px;
          color: #DEDEDE;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 145.4%; /* 20.356px */
        }
        .buttons{
          margin-left: 23px;
          margin-right:23px;
          column-gap: 10px;
          bottom: 18px;
          position: absolute;
          display: flex;
          flex-direction: row;
          .button1{
            width: 122px;
            height: 37px;
            flex-shrink: 0;
            border-radius: 18px;
            border: none;
            outline: none;
            background-color: #4157A4;
            display: flex;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
            &:hover{
              background-color: #6177C4;
            }            
            .button-text{
              margin:auto;
              color: #F7FFF7;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%; /* 14px */
              letter-spacing: -0.28px;
              font-family: 'DM Sans', sans-serif;
            }
          }
          .button2{
            width: 122px;
            height: 37px;
            flex-shrink: 0;
            border-radius: 18px;
            background-color: #152461;
            border: 1px solid #4157A4;
            display: flex;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
            text-decoration: none;
            &:hover{
              background-color: #4157A4;
            }            
            .button-text{
              margin:auto;
              color: #F7FFF7;
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%; /* 14px */
              letter-spacing: -0.28px;
              font-family: 'DM Sans', sans-serif;
            }
          }
        }
      }
    }
  }
}