@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;700&display=swap');

.footer-back{
  border-top: 3px solid #1d2f64;
  background-color: #121D47;
  font-family: 'DM Sans', sans-serif;
  display: flex;
  justify-content: center;
  .footer{
    padding-top: 36px;
    padding-bottom: 36px;
    max-width: 305px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .socials{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      img{
        width:50px;
        height:50px;
      }
    }
    .title{
      margin-top: 20px;
      color: white;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 187.5% */
      opacity: 0.7983863353729248;
      .subtitle{
        color: #4157A4;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }
    .copyright{
      margin-top: 20px;
      color: white;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 100% */
    }
  }
}